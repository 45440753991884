<template>
  <div class="main-content" >
    
  <img class="logo" :src="IMGService.logoMintt" alt="" style="width: 200px"/>

  <b-jumbotron id="jbtron" bg-variant="info" text-variant="white" border-variant="dark">
    <template #header>MyISA <br/></template>

    <template #lead>
      User Guide
    </template>

    <hr class="my-4">
    <p>MyISA Version: {{MYISAVERSION}}
    <br/>
    Documentation Date: {{new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '')}}
    </p>
  </b-jumbotron>

    
    <br/>
    <br/>
    <br/>

    <br><div style="page-break-after:always;"></div>
    <hr/>
    <br><div style="page-break-after:always;"></div>
    

    
    <div class="container-fluid content-row">

      <div id="toc_container">
        <p class="toc_title">Contents</p>
        <ul class="toc_list">
          <GuideTOC v-for="item in items" :key="'toc_' + item.id" :item="item" :level=0 />                 
        </ul>
        </div>

      <br><div style="page-break-after:always;"></div>
      

      <div v-for="item in items" :key="'div_' + item.id">
          <GuidePage :item="item" :level=0 />
          <br><div style="page-break-after:always;"></div>
      </div>
      


    </div>
    
  </div>
</template>



<script>

import { IMGService } from '../../../services/img.service'
import { API_URL } from '@/.env'
import axios from 'axios';
import marked from 'marked';
import ISAKioskHelp from '../../../components/ISA/help/ISAKioskHelp';
import GuidePage from '../../../components/ISA/guide/guide';
import GuideTOC from '../../../components/ISA/guide/toc';
import { VERSIONDATE, MYISAVERSION } from '@/version'

let loaded = false;
// opts are optional
const opts = {
  xhtml: false,
  fqImages: { route: '/uploads', url: API_URL.url + '/doc/res' }, // use custom image endpoint url when link starts with route
  fqLinks: 'https://www.example.com',                                // generate fully qualified links if fqImages is not set
  relPath: true
}

function markedImages(opts) {

  opts = opts || {};
  var imgRoute = opts.fqImages && (opts.fqImages.route || '/images/');
  var imgPrefix = opts.fqImages && opts.fqImages.url;
  var linkPrefix = opts.fqLinks || opts.relPath;
  var xhtml = opts.xhtml;

  return { renderer: {image: renderImage} };

  function renderImage(href, title, text) {

    var out, iframe;
    
    if (imgPrefix && href.startsWith(imgRoute)) { href = imgPrefix + href; }
    else if (linkPrefix && /^\/([^/]|$)/.test(href)) { href = linkPrefix + href; }

    if (href && (m = href.match(/vimeo\/(\d+)/i))) {
      iframe = true;
      out = '<iframe src="//player.vimeo.com/video/' + m[1] + '"' +
              ' frameborder="0" webkitAllowFullScreen mozallowfullscreen allowFullScreen';
    }
    else {
      out = '<img src="' + href + '" alt="' + text + '"';
    }

    var a = (title && title.split(/\s+/)) || [];
    var b = [];
    var classNames = [];
    var m;
    a.forEach(function(w) {
      if ((m = w.match(/^(\d+)x(\d+)$/))) return (out += ' width="' + m[1] + '" height="' + m[2] + '"');
      if ((m = w.match(/^(\w+)=([\w-]+)$/))) {
        if (m[1] === 'class') return classNames.push(m[2]);
        return (out += ' ' + m[1] + '="' + m[2] + '"');
      }
      if ((m = w.match(/^\.([\w-]+)$/))) return classNames.push(m[1]);
      if (w) return b.push(w);
    });

    if (classNames.length) {
      out += ' class="' + classNames.join(' ') + '"';
    }

    title = b.join(' ');

    if (title) {
      out += ' title="' + title + '"';
    }

    out += iframe ? '></iframe>' :
           xhtml ? '/>' :
           '>';

    return out;
  }
};

marked.use(markedImages(opts));

function recursiveHelpItemSearch(items, id) {
  if(items) {
    for(let item of items) {
      if(item.id === id) return item;
      let childSearch = item.children && item.children.length > 0 ? recursiveHelpItemSearch(item.children, id) : null;
      if(childSearch) return childSearch;
    }
  }
}


function processPage(val) {
  
  for(let c in val) {
            if(c.startsWith('content_')) {
              val[c] = marked(val[c]);
            }
          }  
  
   if(val.children)
   for(let child of val.children)
      if(child)
        processPage(child);
}

export default {
  name: "UserGuide",
  data() {
          return {
            IMGService,
            MYISAVERSION,
            items: [{"title_EN":"Loading...","title_FR":"Chargement en cours...", "title_NL":"Loading..."}],
            userguidepage: {},
            
          }
        },

  components: {
    ISAKioskHelp,
    GuideTOC,
    GuidePage
  },
  computed: {
    link() {
      const link = `${window.location.origin}/app/userguide?hi=${this.userguidepage ? this.userguidepage.id : null}`
      return link
    },
  },
  watch: {

  },
  mounted() {    
    const self = this;
      

    const authUrl  = 'https://openapi.mintt.cloud/user/authenticate';

    setInterval(() => {
        if(!loaded) axios.get(`${API_URL.url}/alive`);
    },300);
    debugger
    if(self.$attrs.lang) this.$i18n.locale = self.$attrs.lang;
    
    const result = axios.post(authUrl, { username: 'michel', password: '123ha321!1234', organizationLabel: 'mintt' }).then((result) => {
        const jwt = result.data.data.jwt;

         axios.get(`${API_URL.url}/doc/items`, {
          headers: {
            Authorization: 'Bearer ' + (jwt || self.$attrs.jwt ||  self.$store.state.user.currentUser.jwt)
          }
        }).then((result) => {



        const rawitems = result.data.sort((a,b) => (a.order||100) - (b.order||100));
        this.items = [];
        for(let val of rawitems) {
          processPage(val);

          this.items.push(val)        ;
        }
         
/*
        if(self.$attrs && self.$attrs.hi) {
            
            let item = recursiveHelpItemSearch(self.items, self.$attrs.hi);
            if(!item)
              item = self.items[0];
            self.$store.commit('user/SET_USERGUIDE_PAGE', item); 
            self.userguidepage = this.items[0]                
        } else {
            self.$store.commit('user/SET_USERGUIDE_PAGE', self.items[0]);
            self.userguidepage = self.items[0]                
        }*/
       
            
        }).catch((reason) => {

        })
        loaded = true;
         setTimeout(() => {
          window.status = 'ready_to_print';
        }, 2000);
    });
    
        


   
  },
  methods: {
   marked,
    onCopy(e) {
      this.$bvToast.toast(this.$t('The link to this help page has been copied, you can now share it.\nOnly authenticated people will be able to access it.'), { title: this.$t('Help Page'), solid: true,  variant: 'success',  duration: 3000 });
    },
    onError(e) {
      this.$bvToast.toast(this.$t('Your browser does not support this feature.'), { title: this.$t('Help Page'), solid: true,  variant: 'warning',  duration: 3000 });
    },  
  }
};
</script>

<style lang="scss" >
.main-content {
  margin: 100px
}
.my-flex-card {
    height: calc(100% - 15px);
    margin-bottom: 15px;
}

.TreeNavigation {
    display: inline-block;
    padding: 0;
    margin: 0;
}


.NavigationToggle__icon {
    display: inline-block;
    padding: 3px;
    border: solid #000;
        border-top-width: medium;
        border-right-width: medium;
        border-bottom-width: medium;
        border-left-width: medium;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.NavigationToggle {
    position: relative;
    top: -3px;
    padding: 5px 5px 5px 3px;
    cursor: pointer;
}

.guidecontent {
  font-size: x-large;
}

#toc_container {
    background: #f9f9f9 none repeat scroll 0 0;
    border: 1px solid #aaa;
    display: table;
    font-size: 95%;
    margin-bottom: 1em;
    padding: 20px;
    width: auto;
}

.toc_title {
    font-weight: 700;
    text-align: center;
}

#toc_container li, #toc_container ul, #toc_container ul li{
    list-style: outside none none !important;
}

#jbtron{
  
  margin-top: 50px;
  text-align: center;
}
.jumbotron {
    background-color: #88cabe !important; 
    
}

#jbtron h1 {
  font-weight: 900 !important;
  margin: 0 0 50px;  
  font-weight: 900;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  color: #000000;
}

.lead {
    font-weight: 900 !important;
  margin: 0 0 50px;  
  font-weight: 900;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  color: #000000;
}

</style>
