<template>
        <div class="h-100">
        <h1 :id="item.id" v-if="parseInt(level) === 0" style="color: #88cabe; font-weight: bolder; font-size: xx-large">{{ item ? item['title_'+$i18n.locale.toUpperCase()]: '' }}</h1>
        <h2 :id="item.id" v-if="parseInt(level) === 1" style="color: #88cabe; font-weight: bolder; font-size: xx-large">{{ item ? item['title_'+$i18n.locale.toUpperCase()]: '' }}</h2>
        <h3 :id="item.id" v-if="parseInt(level) === 2" style="color: #88cabe; font-weight: bolder; font-size: xx-large">{{ item ? item['title_'+$i18n.locale.toUpperCase()]: '' }}</h3>
        <h4 :id="item.id" v-if="parseInt(level) === 3" style="color: #88cabe; font-weight: bolder; font-size: xx-large">{{ item ? item['title_'+$i18n.locale.toUpperCase()]: '' }}</h4>
        <h5 :id="item.id" v-if="parseInt(level) === 4" style="color: #88cabe; font-weight: bolder; font-size: xx-large">{{ item ? item['title_'+$i18n.locale.toUpperCase()]: '' }}</h5>

        <div class="ml-auto p-2"></div>
        <br/>
        <p class="guidecontent" v-html="item ? item['content_'+$i18n.locale.toUpperCase()] : ''"></p>
        <ISAKioskHelp v-if="item && item.id == '612ec7ca94c36101415a010a'" :skipHeader="true">          
        </ISAKioskHelp>

        <div v-for="child in item.children" :key="'c_' + child.id">
                <br/>
                <br/>
                <UserGuidePage :item="child" :level="parseInt(level)+1"/>
        </div>
        </div>
</template>



<script>
import marked from 'marked';
import ISAKioskHelp from '../help/ISAKioskHelp';
import { API_URL } from '@/.env'
// opts are optional
const opts = {
  xhtml: false,
  fqImages: { route: '/uploads', url: API_URL.url + '/doc/res' }, // use custom image endpoint url when link starts with route
  fqLinks: 'https://www.example.com',                                // generate fully qualified links if fqImages is not set
  relPath: true
}



export default {
  name: "UserGuidePage",
  props: {
      item: { type: Object },
      lang: { type: String },
      jwt: { type: String },
      level: { type: Number}
  },
  data() {
          
  },

  components: {
    ISAKioskHelp
  },
  computed: {
    
  },
  watch: {
    
  },
  mounted() {

  },
  methods: {
   
  }
};
</script>

<style lang="scss" scoped>
.my-flex-card {
    height: calc(100% - 15px);
    margin-bottom: 15px;
}

.TreeNavigation {
    display: inline-block;
    padding: 0;
    margin: 0;
}


.NavigationToggle__icon {
    display: inline-block;
    padding: 3px;
    border: solid #000;
        border-top-width: medium;
        border-right-width: medium;
        border-bottom-width: medium;
        border-left-width: medium;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.NavigationToggle {
    position: relative;
    top: -3px;
    padding: 5px 5px 5px 3px;
    cursor: pointer;
}

.guidecontent {
  font-size: x-large;
}

</style>